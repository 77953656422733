import React from "react"
import theme from "theme"
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"services"} />
      <Helmet>
        <title>Наші послуги | Наші послуги на автомийці XovileXusa</title>
        <meta
          name={"description"}
          content={"Бездоганна увага до кожної деталі\n"}
        />
        <meta
          property={"og:title"}
          content={"Наші послуги | Наші послуги на автомийці XovileXusa"}
        />
        <meta
          property={"og:description"}
          content={"Бездоганна увага до кожної деталі"}
        />
        <meta
          property={"og:image"}
          content={"https://win.xovilexusa.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://win.xovilexusa.com/img/car-wash.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://win.xovilexusa.com/img/car-wash.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://win.xovilexusa.com/img/car-wash.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://win.xovilexusa.com/img/car-wash.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://win.xovilexusa.com/img/car-wash.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://win.xovilexusa.com/img/car-wash.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="80px 0 80px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Schedule-8"
      >
        <Override
          slot="SectionContent"
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="48px 30px"
          lg-grid-template-columns="1fr"
          lg-grid-gap="30px"
          md-grid-gap="32px"
        />
        <Box
          flex-direction="column"
          md-width="100%"
          md-align-items="center"
          md-justify-content="center"
          md-text-align="center"
          align-items="center"
          display="flex"
          grid-column="1 / span 3"
          lg-grid-column="auto / auto"
        >
          <Text
            margin="0px 0px 16px 0px"
            font="--headline1"
            color="--dark"
            md-text-align="center"
            sm-font='normal 700 32px/1.2 "Source Sans Pro", sans-serif'
            text-align="center"
          >
            Наші послуги
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            font="--lead"
            color="--darkL2"
            md-text-align="center"
            text-align="center"
            max-width="800px"
            sm-font="normal 400 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
          >
            На автомийці XovileXusa ми пропонуємо різноманітні послуги, розроблені для задоволення потреб кожного клієнта та кожного автомобіля. Від базового миття до повної обробки деталей - наша мета полягає в тому, щоб надати вам винятковий досвід, який залишить ваш автомобіль блискучим, а ваші очікування - перевершеними.
          </Text>
        <Text
          margin="50px 0px 14px 0px"
          color="--darkL2"
          font="--headline2"
          lg-text-align="left"
        >
          Комплексний догляд за автомобілем
        </Text>
        </Box>

        <Box
          padding="40px 40px 40px 40px"
          lg-padding="45px 30px"
          md-padding="45px 45px"
          background="#FFFFFF"
          border-radius="24px"
          justify-content="flex-start"
          flex-direction="column"
          display="flex"
        >
          <Text
            margin="0px 0px 14px 0px"
            color="--darkL2"
            font="--headline3"
            lg-text-align="left"
          >
            Камери самообслуговування
          </Text>
          <Text
            margin="0px 0px 46px 0px"
            color="--greyD3"
            font="--base"
            lg-text-align="left"
            flex="1 0 auto"
          >
            Повний контроль: Оснащені зручними для користувача інтерфейсами та першокласними інструментами для чищення.
            <br />
            <br />
   Мийки високого тиску: Видаляйте бруд і наліт без особливих зусиль.
            <br />
            <br />
   Поролонові щітки: М'яке очищення делікатних поверхонь без подряпин.
          </Text>
        </Box>
        <Box
          padding="40px 40px 40px 40px"
          lg-padding="45px 30px"
          md-padding="45px 45px"
          background="#FFFFFF"
          border-radius="24px"
          justify-content="flex-start"
          flex-direction="column"
          display="flex"
        >
          <Text
            margin="0px 0px 14px 0px"
            color="--darkL2"
            font="--headline3"
            lg-text-align="left"
          >
            Безконтактне автоматичне миття
          </Text>
          <Text
            margin="0px 0px 46px 0px"
            color="--greyD3"
            font="--base"
            lg-text-align="left"
            flex="1 0 auto"
          >
            Делікатне, але ефективне: Ідеально підходить для тих, хто хоче швидкого та ретельного очищення без жодного контакту.
            <br />
            <br />   Сенсорно-керовані форсунки: Адаптуються до форми та розміру вашого автомобіля для оптимального очищення.
            <br />
            <br />   Екологічні миючі засоби: Ефективне очищення, безпечне для вашого автомобіля та довкілля.
          </Text>
        </Box>
        <Box
          padding="40px 40px 40px 40px"
          lg-padding="45px 30px"
          md-padding="45px 45px"
          background="#FFFFFF"
          border-radius="24px"
          justify-content="flex-start"
          flex-direction="column"
          display="flex"
        >
          <Text
            margin="0px 0px 14px 0px"
            color="--darkL2"
            font="--headline3"
            lg-text-align="left"
          >
            Послуги автосервісу
          </Text>
          <Text
            margin="0px 0px 46px 0px"
            color="--greyD3"
            font="--base"
            lg-text-align="left"
            flex="1 0 auto"
          >
            Орієнтована на деталі досконалість: Для оздоблення салону.
            <br />
            <br />
            Деталізація інтер'єру: Глибоке очищення та стан оббивки, килимів тощо.
            <br />
            <br />
            Догляд за зовнішнім виглядом: Нанесення воску та полірування для захисту та покращення блиску вашого автомобіля.
          </Text>
        </Box>
        <Box
          padding="40px 40px 40px 40px"
          lg-padding="45px 30px"
          md-padding="45px 45px"
          background="#FFFFFF"
          border-radius="24px"
          justify-content="flex-start"
          flex-direction="column"
          display="flex"
        >
          <Text
            margin="0px 0px 14px 0px"
            color="--darkL2"
            font="--headline3"
            lg-text-align="left"
          >
            Очищення ходової частини
          </Text>
          <Text
            margin="0px 0px 46px 0px"
            color="--greyD3"
            font="--base"
            lg-text-align="left"
            flex="1 0 auto"
          >
            Ретельне та захисне: Продовжить термін служби вашого автомобіля.
            <br />
            <br />
            Інгібітор іржі: Захищає від корозії та зносу.
            <br />
            <br />
            Ополіскування під високим тиском: видаляє сіль, бруд і сміття.
          </Text>
        </Box>
        <Box
          padding="40px 40px 40px 40px"
          lg-padding="45px 30px"
          md-padding="45px 45px"
          background="#FFFFFF"
          border-radius="24px"
          justify-content="flex-start"
          flex-direction="column"
          display="flex"
        >
          <Text
            margin="0px 0px 14px 0px"
            color="--darkL2"
            font="--headline3"
            lg-text-align="left"
          >
           Очищення коліс
          </Text>
          <Text
            margin="0px 0px 46px 0px"
            color="--greyD3"
            font="--base"
            lg-text-align="left"
            flex="1 0 auto"
          >
            Блискучі диски та шини: Тому що кожна деталь має значення.
            <br />
            <br />
            Блиск для коліс: Відновлює та захищає блиск ваших коліс.
          </Text>
        </Box>
        <Box
          padding="40px 40px 40px 40px"
          lg-padding="45px 30px"
          md-padding="45px 45px"
          background="#FFFFFF"
          border-radius="24px"
          justify-content="flex-start"
          flex-direction="column"
          display="flex"
        >
          <Text
            margin="0px 0px 14px 0px"
            color="--darkL2"
            font="--headline3"
            lg-text-align="left"
          >
           Сезонні пакети догляду
          </Text>
          <Text
            margin="0px 0px 46px 0px"
            color="--greyD3"
            font="--base"
            lg-text-align="left"
            flex="1 0 auto"
          >
           Рішення для кожної пори року: Розроблені для захисту вашого автомобіля незалежно від погоди.
            <br />
            <br />
            Зимовий захист: Спеціалізовані засоби для боротьби з дорожніми солями та низькими температурами.
            <br />
            <br />
            Літній блиск: Віск із захистом від ультрафіолету та засоби для догляду за вікнами.
          </Text>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-10"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-x="hidden"
            overflow-y="hidden"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 70% 0px"
            width="100%"
            height="auto"
            position="relative"
            hover-transform="translateY(-10px)"
          >
            <Image
              left={0}
              src="https://win.xovilexusa.com/img/4.jpg"
              object-fit="cover"
              width="100%"
              top="auto"
              right={0}
              bottom="0px"
              min-height="100%"
              position="absolute"
              display="block"
            />
          </Box>
        </Box>
        <Box
          flex-direction="column"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          lg-order="1"
          width="50%"
          align-items="flex-start"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          display="flex"
        >
          <Text
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            sm-font="--headline3"
            md-text-align="left"
          >
           Завітайте до нас, щоб дізнатись більше
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
            Наш повний спектр послуг розроблений таким чином, щоб задовольнити всі ваші потреби, гарантуючи, що ваш автомобіль отримає догляд, на який він заслуговує. Для отримання більш детальної інформації про кожну з наших послуг, будь ласка, відвідайте сайт автомийки XovileXusa або зв'яжіться з нами напряму. Наша дружня команда готова допомогти вам і надати індивідуальну консультацію, виходячи з конкретних вимог вашого автомобіля.
          </Text>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
